@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');
* {
    box-sizing: border-box;
}

.login {
    background: #f6f5f7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.login h1 {
    font-weight: bold;
    margin: 0;
}

.login h2 {
    text-align: center;
}

.login p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

.login span {
    font-size: 12px;
}

.login a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
    white-space: nowrap;
}

.login button {
    border-radius: 20px;
    border: 1px solid #79090b;
    background-color: #79090b;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}

.login button:active {
    transform: scale(0.95);
}

.login button:focus {
    outline: none;
}

.login button.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
}

.login form {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 2rem;
    height: 100%;
    text-align: center;
    gap: 0.5rem;
}

.login input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

.login .container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
}

.login .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.login .sign-in-container {
    left: 0;
    width:50%;
    z-index: 2;
}

@media screen and (max-width: 500px) {
    .login .sign-in-container {
    width:100%;
    }
    .login .container {
    min-height: 540px;

    }
}

.login .container.right-panel-active .sign-in-container {
    transform: translateX(100%);
    /* animation: show 0.6s; */
}

.login .sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.login .container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

.login .tiet-logo {
    height: 5rem;
}

@keyframes show {
    0%,
    49.99% {
        opacity: 0;
        z-index: 1;
    }
    50%,
    100% {
        opacity: 1;
        z-index: 5;
    }
}

.login .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.login .container.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

.login .overlay {
    background: #79090b;
    background: -webkit-linear-gradient(to right, #ec232b, #79090b);
    background: linear-gradient(to right, #ec232b, #79090b);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.login .container.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-left {
    transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
    transform: translateX(0);
    background: linear-gradient(to left, #ec232b, #79090b);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.social-container {
    margin: 20px 0;
}

.social-container a {
    border: 1px solid #DDDDDD;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: red;
}

footer a {
    color: #3c97bf;
    text-decoration: none;
}

.error-message {
    font-size: 0.8rem;
    text-align: left;
    color: #79090b;
}